import "@csstools/normalize.css/opinionated.css";
import '../styles/index.scss';

const IsTouch = window.matchMedia("(any-hover: none)").matches;

// Deal with video player overlay
const videos = document.getElementsByClassName('video');

for (let video of videos) {
    const btn = video.querySelector('.video__btn');
    const carousel = video.querySelector('.video__carousel');

    btn.addEventListener("click", () => {
        video.classList.add("playing");
        animatePanel(carousel);
    }, false);
}

// Rotate through backgrounds in header panels
const panels = document.getElementsByClassName('hero__panel');

for (let panel of panels) {
    animatePanel(panel);
}

function animatePanel(panel) {
    const children = panel.children;
    const length = children.length;
    let current = 0;
    let playing = true;

    const initialDelay = Number(panel.dataset.delay) || 0;
    const duration = Number(panel.dataset.duration) || 8000;
    const pause = panel.dataset.pause === "true";

    function startTimer(delay = 0) {
        setTimeout(() => {
            if(playing) {
                const next = current < length - 1 ? current + 1 : 0;

                if(children[next].nodeName === 'VIDEO') {
                    children[next].play();
                }

                children[next].style.zIndex = 0;
                children[next].style.opacity = 1;

                children[current].style.zIndex = -1;
                children[current].style.opacity = 0;

                if(children[current].nodeName === 'VIDEO') {
                    children[current].load();
                }

                current = next;
            }

            startTimer();
        }, delay + duration);
    }

    if(playing && children[current].nodeName === 'VIDEO') {
        children[current].play();
    }

    startTimer(initialDelay);

    if(pause) {
        if(IsTouch) {
             panel.parentNode.addEventListener("touchstart", () => {
                console.log("touch");
                playing = false;
            }, false);

            panel.parentNode.addEventListener("touchend", () => {
                console.log("end");
                playing = true;
            }, false);
        } else {
             panel.parentNode.addEventListener("mouseenter", () => {
                playing = false;
            }, false);

            panel.parentNode.addEventListener("mouseleave", () => {
                playing = true;
            }, false);
        }
    }
}

// Make cards Backgrounds show on tap on mobile
const cards = document.getElementsByClassName('card--book');

for (let card of cards) {
    card.addEventListener("touchstart", () => {
        card.classList.add('tapped');
    }, false);

    card.addEventListener("touchend", () => {
        card.classList.remove('tapped');
    }, false);
}

// Header Logo interaction
// On hover on capable devices otherwise on scroll
const box = document.getElementById("hero");
const logo = document.getElementById("logo");
const logoImg = logo.querySelector("img");
const logoMask = logo.querySelector(".mask");

if(IsTouch) {
    startScrollObserver();
} else {
    startHoverObserver();
}

function startHoverObserver() {
    box.addEventListener("mousemove", hoverUpdate, false);
    box.addEventListener("mouseenter", hoverUpdate, false);
    box.addEventListener("mouseleave", hoverUpdate, false);

    function hoverUpdate(event) {
        updateDisplay(event.pageX / box.offsetWidth * 2);
    }
}

function updateDisplay(x) {
  let r = 255;
  let g = 255;
  let b = 255;
  let opacity = 1;

  if(x < 1) {
    r = 255 - (1 - x) * (255 - 2);
    g = 255 - (1 - x) * (255 - 78);
  } else {
    opacity = 0.6;
    g = 255 - (x - 1) * (255 - 78);
    b = 255 - (x - 1) * (255 - 2);
  }

  logoImg.style.filter = "brightness("+ x +")";
  logoMask.style.filter = "brightness("+ x +")";
  logoMask.style.backgroundColor = "rgba(" + r + " " + g + " " + b + " / " + opacity + ")";
}

function startScrollObserver() {
    let observerOptions = {
        root: null,
        rootMargin: "0px",
        threshold: []
    };

    for (let i=0; i<=1.0; i+= 0.01) {
        observerOptions.threshold.push(i);
    }

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            updateDisplay(entry.intersectionRatio);
        });
    }, observerOptions);
    observer.observe(box);
}

const playBtn = document.getElementById("playbtn");
const player = document.getElementById("audioplayer");
let playing = false;

playBtn.addEventListener("click", () => {
    if (playing) {
        player.pause();
        player.currentTime = 0;
        playBtn.classList.remove('playing');
        playing = false;
    } else {
        player.play();
        playBtn.classList.add('playing');
        playing = true;
    }
}, false);
